
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        










.info__wrapper {
  padding: 20px 30px;
  background-color: #fafafc;
  display: flex;

  @media screen and (max-width: 720px) {
    padding: 20px 15px;
    display: block;
  }
}

.info__svg {
  fill: $c-orange;
  width: 40px;
  height: 30px;
  margin-right: 20px;

  @media screen and (max-width: 720px) {
    width: 20px;
  }
}

.info__text {
  color: $c-gray-dark;
  font-size: 14px;
}
