
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        























































































































































































































.bold {
  font-weight: 600;
}

button {
  border: none;
  border-radius: 50px;
  background-color: $c-main-primary;
  color: white;
  outline: 0;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px 8px 12px;
}

button.action {
  align-items: center;
  background: rgba(247, 166, 0, 0.2);
  border-radius: 50px;
  color: $c-main-primary;
  display: flex;
  flex-direction: row;
  font-size: 0.9em;
  padding: 0.4em 5px 0.4em 1.5em;
}

button.action--big--btn {
  ::v-deep {
    padding: 1.4rem 2.8rem;
  }
}

.doc-button button,
.doc-button button.action--btn,
.doc-button button.action--big--btn,
.doc-button button.action--big--btn.checked {
  margin-bottom: 0;
  padding: 1.3rem 2.8rem;
  width: 100%;
}

button.action--big--btn:disabled {
  background-color: #ececec;
  color: $c-gray-dark;
  padding: 1rem 2.8rem;
}

.action__label {
  display: block;
  width: 100%;
}

.flex-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

button:hover,
a:hover {
  opacity: 0.7;
}

.flex-row.bottom {
  align-items: flex-end;
}

.flex-row .end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.steps {
  background: #f3f3f3;
  counter-reset: step;
}

.steps--title {
  font-family: Swiss721BT, sans-serif;
  padding: 2em 0;
  margin: 0;
  font-weight: 600;
}

.steps--items {
  padding: 24px 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.steps--items:before {
  content: '';
  display: block;
  width: 100%;
  height: 4px;
  position: absolute;
  top: 20px;
  left: 0;
  background: #c6c6c6;
}

.steps--item {
  position: relative;
  padding: 24px 0;
  flex: 1 1 0;
}

.steps--item.active:before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background: white;
  border: 3px solid hsla(355, 100%, 50%, 1);
  border-radius: 100px;
  position: absolute;
  top: -13px;
  left: 0;
}

.steps--item.checked:before {
  content: '';
  display: block;
  width: 105%;
  height: 4px;
  position: absolute;
  top: -4px;
  left: 0;
  background: hsla(104, 86%, 38%, 1);
}

.steps--item.checked:first-child:after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background: center url('../../assets/images/check.svg') no-repeat;
  background-size: contain;
  position: absolute;
  top: -14px;
}

.steps--item__title {
  font-weight: 600;
  margin: 0;
  font-size: 1.5rem;
}

.steps--item__info {
  margin: 0;
  font-size: 1.4rem;
  color: hsla(0, 0%, 63%, 1);
}

.steps--item__title:before {
  counter-increment: step;
  content: counter(step) '. ';
}

.steps--item.checked .steps--item__title {
  color: hsla(104, 86%, 38%, 1);
}

.steps--item.active .steps--item__title {
  color: hsla(355, 100%, 50%, 1);
}

.steps--item.active .steps--item__info {
  color: hsla(0, 0%, 20%, 1);
}

@media screen and (max-width: 960px) {
  .steps--item {
    visibility: hidden;
    width: 0;
    height: 0;
    flex: none;
    padding: 24px 3px;
  }

  .steps--item.active,
  .steps--item.active + .steps--item {
    visibility: visible;
    width: 48%;
    height: auto;
  }
}

/***********************************************/
.steps-small {
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 1em 1.5em 1em 1em;
  margin: 0 1em 1em 0;
  @media screen and (max-width: 560px) {
    padding-left: 1.5em;
  }
}

.steps-small--illu {
  max-width: 130px;
  float: left;
  margin-right: 5em;
  @media screen and (max-width: 768px) {
    width: 65px;
  }
  @media screen and (max-width: 560px) {
    display: none;
  }
}

.steps-small--header--img {
  width: 35px;
  float: right;
}

.steps-small--title {
  color: $c-gray-darker;
  font-size: 17px;
  margin: 10px 0;
}

.steps-small--status {
  font-size: 1.7rem;
  font-weight: normal;
  color: $c-main-primary;
  margin: 0.3em 0;
}

.finished .steps-small--status {
  color: #5dae99;
}

.closed .steps-small--status {
  color: $c-gray-dark;
}

.steps-small--items {
  position: relative;
  margin: 2em 0 1em;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.steps-small--items:before {
  content: '';
  position: absolute;
  width: 100%;
  border-top: 4px solid #e1e1e1;
}

.steps-small--item {
  display: flex;
  position: relative;
  z-index: 2;
  width: calc(100% / 9);
  border-top: 4px solid transparent;
}

.in-progress .steps-small--item.checked,
.finished .steps-small--item.checked {
  border-top: 4px solid #5dae99;
}

.closed .steps-small--item.checked {
  border-top: 4px solid #a0a0a0;
}

.steps-small--infos {
  color: $c-gray-darker;
  font-size: 1.4rem;
  font-weight: normal;
}

.icon-elec {
  display: inline-block;
  width: 1em;
  height: 1em;
}

.icon-gaz {
  display: inline-block;
  width: 1em;
  height: 1em;
}
